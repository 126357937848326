<template>
    <div>
        <div class="row page-title-header">
            <div class="col-12">
                <div class="page-header">
                    <b-breadcrumb class="m-0">
                        <b-breadcrumb-item :to="{
                            name: 'DashboardHome',
                        }">
                            <i class="fa fa-home"></i>
                        </b-breadcrumb-item>
                        <b-breadcrumb-item :to="{
                            name: 'PointRuleList',
                        }">入點比例管理</b-breadcrumb-item>
                        <b-breadcrumb-item :to="{
                            name: 'PointRuleList',
                        }">入點比例列表</b-breadcrumb-item>
                        <b-breadcrumb-item active>{{
                            isEditing ? "編輯入點比例" : "新增入點比例"
                        }}
                        </b-breadcrumb-item>
                    </b-breadcrumb>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <b-overlay :show="showLoading">
                    <b-card>
                        <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
                            <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
                                {{
                                    isEditing ? "編輯入點比例" : "新增入點比例"
                                }}
                            </h4>
                            <div class="col-12 col-xl-8 mt-3">
                                <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="通路 id">
                                    <b-form-select v-model="merchantId" :options="merchants"></b-form-select>
                                </b-form-group>

                                <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="比對值">
                                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" v-model="pointRule.key"></b-form-input>
                                </b-form-group>

                                <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="點數/倍數">
                                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" v-model="pointRule.value"></b-form-input>
                                </b-form-group>

                                <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="比對值類型">
                                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" v-model="pointRule.type"></b-form-input>
                                </b-form-group>

                                <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="活動代碼">
                                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" v-model="pointRule.event_id"></b-form-input>
                                </b-form-group>

                                <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="順序">
                                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" v-model="pointRule.order"></b-form-input>
                                </b-form-group>

                                <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="分類">
                                    <b-form-input class="mb-2 mr-sm-2 mb-sm-0" v-model="pointRule.cate"></b-form-input>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center">
                            <b-button class="mr-3" @click="handleCancel" variant="outline-danger">返回
                            </b-button>
                            <b-button @click="handleSubmit" variant="success">儲存
                            </b-button>
                        </div>
                    </b-card>
                </b-overlay>
            </div>
        </div>
    </div>
</template>
  
<script>
import { mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import pointRuleApi from "../../../apis/point-rule";
import merchantApi from "../../../apis/merchant";

export default {
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            merchantId: null,
            merchants: [{ value: null, text: "請選擇" }],
            showLoading: false,
            isEditing: true,
            pointRule: {
                key: '',
                value: '',
                type: '',
                event_id: '',
                order: '',
                cate: ''
            },
        };
    },

    computed: {
        ...mapState("general", {
            organization: (state) => state.organization,
        }),
        pointRuleId() {
            return this.$route.params.pointRuleId;
        }
    },
    mounted() {
        if (!this.pointRuleId) {
            this.clearPointRule();
            this.isEditing = false;
        } else {
            this.fetchPointRule();
            this.isEditing = true;
        }
        this.fetchMerchants();
    },
    methods: {
        async fetchPointRule() {
            this.showLoading = true;
            const response = await pointRuleApi.getPointRule(
                this.pointRuleId
            );
            this.pointRule = response.data.data;
            this.merchantId = response.data.data.merchant_id;
            this.showLoading = false;
        },
        clearPointRule() {
            this.pointRule = {
                key: '',
                value: '',
                type: '',
                event_id: '',
                order: '',
                cate: ''
            };
        },
        async handleSubmit() {
            try {
                if (this.isEditing) {
                    let response = await pointRuleApi.updatePointRule(
                        this.merchantId,
                        this.pointRuleId,
                        this.pointRule
                    );
                    if (response.status && response.status === 200) {
                        this.$swal.fire({
                            title: "更新成功",
                            type: "success",
                        });

                        this.$router.push({ name: "PointRuleList" });
                    }
                } else {
                    let response = await pointRuleApi.storePointRule(this.merchantId, this.pointRule);

                    if (response.status && response.status >= 200) {
                        this.$swal.fire({
                            title: "新增成功",
                            type: "success",
                        });

                        this.$router.push({ name: "PointRuleList" });
                    }
                }
            } catch (error) {
                if (error.response.status === 422 && error.response.data.message) {
                    const html = Object.values(error.response.data.message)
                        .map((m) => m[0])
                        .join("<br/>");
                    this.$swal.fire({
                        type: "error",
                        html,
                    });
                } else {
                    this.$swal.fire({
                        title: this.isEditing ? "更新失敗" : "新增失敗",
                        type: "error",
                    });
                }
            }
        },
        handleCancel() {
            this.$router.push({ name: "PointRuleList" });
        },
        async fetchMerchants() {
            const { data } = await merchantApi.list();

            this.merchants = [
                { value: null, text: "請選擇" },
                ...data.data.map((merchant) => {
                    return {
                        value: merchant.id,
                        text: `${merchant.name} (${merchant.type_name})`,
                    };
                }),
            ];
        },
    },
};
</script>
  